import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./style.css";

function Measures({
  index,
  removeMed,
  formatWeight,
  onPesoTotal,
  register,
  errors,
}) {
  const [volCobrable, setVolCobrable] = useState("");
  const [tSGetQuoteStepThree] = useTranslation("getQuoteStepThree");

  const [data, setData] = useState({
    quantity: "",
    alto: "",
    largo: "",
    ancho: "",
    pesoCobrable: Number(volCobrable),
  });

  const handleData = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    const todasLasPropiedadesLlenas =
      data[`quantity_${index}`] !== "" &&
      data[`alto_${index}`] !== "" &&
      data[`largo_${index}`] !== "" &&
      data[`ancho_${index}`] !== "";

    if (todasLasPropiedadesLlenas) {
      const res =
        Number(data[`alto_${index}`]) *
        Number(data[`largo_${index}`]) *
        Number(data[`ancho_${index}`]);
      const VolCob = (Number(res) / 5000) * Number(data[`quantity_${index}`]);

      setVolCobrable(Number(Math.round(VolCob * 100) / 100));
      onPesoTotal(Number(Math.round(VolCob * 100) / 100));
    } else {
      setVolCobrable(null);
    }
  }, [data, index, onPesoTotal]);

  useEffect(() => {
    setData((prevState) => ({
      ...prevState,
      pesoCobrable: Number(volCobrable),
    }));
  }, [volCobrable]);

  return (
    <>
      <div className="shipment__form__dimensions">
        <div className="shipment__form__dimensions__count">
          <span></span>
        </div>

        <div className="shipment__form__dimensions__delete" onClick={removeMed}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"></path>
          </svg>
        </div>

        <div className="column">
          <label htmlFor={`quantity_${index}`} data-text="cant.">
            {tSGetQuoteStepThree("Amount")}
          </label>
          <div className="shipment__form__dimensions__number">
            <input
              type="text"
              name={`quantity_${index}`} // Utiliza un nombre único para cada campo
              id={`quantity_${index}`}
              {...register(`quantity_${index}`, {
                required: true,
                pattern: /^[0-9]+(\.[0-9]+)?$/,
              })}
              pattern="^[0-9]+(\.[0-9]+)?$"
              maxLength={8}
              className={`js-shipment-quote-form-quantity ${
                errors[`quantity_${index}`]?.type === "pattern"
                  ? "missing-field"
                  : ""
              }`}
              onChange={handleData}
            />
            {errors[`quantity_${index}`]?.type === "required" && (
              <div
                className="shipment__form__column__message__services show_alert"
                role="alert"
              >
                {tSGetQuoteStepThree("missingField")}
              </div>
            )}
          </div>
          <div className="shipment__form__column__message" role="alert"></div>
        </div>

        <div className="column">
          <label htmlFor={`alto_${index}`} data-text="height">
            {tSGetQuoteStepThree("Height")}
          </label>
          <div className="shipment__form__dimensions__number">
            <input
              type="text"
              name={`alto_${index}`} // Utiliza un nombre único para cada campo
              id={`alto_${index}`}
              {...register(`alto_${index}`, {
                required: true,
                pattern: /^[0-9]+(\.[0-9]+)?$/,
              })}
              pattern="^[0-9]+(\.[0-9]+)?$"
              maxLength={8}
              className={`js-shipment-quote-form-alto ${
                errors[`alto_${index}`]?.type === "pattern"
                  ? "missing-field"
                  : ""
              }`}
              onChange={handleData}
            />
            {errors[`alto_${index}`]?.type === "required" && (
              <div
                className="shipment__form__column__message__services show_alert"
                role="alert"
              >
                {tSGetQuoteStepThree("missingField")}
              </div>
            )}
          </div>
          <div className="shipment__form__column__message" role="alert"></div>
        </div>

        <div className="column">
          <label htmlFor={`largo_${index}`} data-text="height">
            {tSGetQuoteStepThree("Length")}
          </label>
          <div className="shipment__form__dimensions__number">
            <input
              type="text"
              name={`largo_${index}`} // Utiliza un nombre único para cada campo
              id={`largo_${index}`}
              {...register(`largo_${index}`, {
                required: true,
                pattern: /^[0-9]+(\.[0-9]+)?$/,
              })}
              pattern="^[0-9]+(\.[0-9]+)?$"
              maxLength={8}
              className={`js-shipment-quote-form-largo ${
                errors[`largo_${index}`]?.type === "pattern"
                  ? "missing-field"
                  : ""
              }`}
              onChange={handleData}
            />
            {errors[`largo_${index}`]?.type === "required" && (
              <div
                className="shipment__form__column__message__services show_alert"
                role="alert"
              >
                {tSGetQuoteStepThree("missingField")}
              </div>
            )}
          </div>
          <div className="shipment__form__column__message" role="alert"></div>
        </div>

        <div className="column">
          <label htmlFor={`ancho_${index}`} data-text="height">
            {tSGetQuoteStepThree("Width")}
          </label>
          <div className="shipment__form__dimensions__number">
            <input
              type="text"
              name={`ancho_${index}`} // Utiliza un nombre único para cada campo
              id={`ancho_${index}`}
              {...register(`ancho_${index}`, {
                required: true,
                pattern: /^[0-9]+(\.[0-9]+)?$/,
              })}
              pattern="^[0-9]+(\.[0-9]+)?$"
              maxLength={8}
              className={`js-shipment-quote-form-ancho ${
                errors[`ancho_${index}`]?.type === "pattern"
                  ? "missing-field"
                  : ""
              }`}
              onChange={handleData}
            />
            {errors[`ancho_${index}`]?.type === "required" && (
              <div
                className="shipment__form__column__message__services show_alert"
                role="alert"
              >
                {tSGetQuoteStepThree("missingField")}
              </div>
            )}
          </div>
          <div className="shipment__form__column__message" role="alert"></div>
        </div>

        <div className="shipment__form__dimensions__result">
          <span
            className={`js-peso-cobrable-element ${
              volCobrable ? "visble" : ""
            }`}
          >
            <i className="js-peso-cobrable-text">
              {tSGetQuoteStepThree("volumetricWeight")}:{" "}
              <strong>{formatWeight(volCobrable)} kg</strong>
            </i>
            <data className="js-peso-cobrable-result"></data>
          </span>
        </div>
      </div>
      <div className="shipment__form__dimensions__elements"></div>
    </>
  );
}

export default Measures;
