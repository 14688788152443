import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./style.css";

function Summary({ data }) {
  const [tNabvar] = useTranslation("navbar");
  const [tSGetQuoteStepSumary] = useTranslation("getQuoteStepSumary");
  const [tSGetQuoteStepTwo] = useTranslation("getQuoteStepTwo");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsCheckboxChecked((prevValue) => !prevValue);
  };

  return (
    <>
      <div className="shipment__table">
        <div className="shipment__table__layout">
          <div className="shipment__table__title">
            <h2
              className="set fadeInUp"
              style={{ visibility: "visible", animation: "fadeInUp" }}
            >
              {tSGetQuoteStepSumary("title.text1")}
            </h2>
          </div>

          <div className="shipment__table__information">
            <h2
              className="set fadeInUp"
              style={{ visibility: "visible", animation: "fadeInUp" }}
            >
              {tSGetQuoteStepSumary("title.text2")}
            </h2>
            <ul role="table">
              <li>
                <div className="shipment__table__header">
                  <div className="is-column-seven">
                    {tSGetQuoteStepSumary("product")}
                  </div>
                  <div className="is-column-seven">
                    {tSGetQuoteStepSumary("paidAt")}
                  </div>
                  <div className="is-column-seven">
                    {tSGetQuoteStepSumary("deliveryAt")}
                  </div>
                  <div className="is-column-seven">
                    {tSGetQuoteStepSumary("origin")}
                  </div>
                  <div className="is-column-seven">
                    {tSGetQuoteStepSumary("destination")}
                  </div>
                  <div className="is-column-seven">
                    {tSGetQuoteStepSumary("piece")}
                  </div>
                  <div className="is-column-seven">
                    {tSGetQuoteStepSumary("weight")}
                  </div>
                </div>

                <div className="shipment__table__body">
                  <div className="is-column-seven">
                    <label>{tSGetQuoteStepSumary("product")}</label>
                    <data className="js-shipment-table-product">
                      {data.tipo_servicio !== "" ? data.tipo_servicio : "N/A"}
                    </data>
                  </div>

                  <div className="is-column-seven">
                    <label>{tSGetQuoteStepSumary("paidAt")}</label>
                    <data className="js-shipment-table-pago">
                      {data.pago !== ""
                        ? data.pago
                        : tSGetQuoteStepSumary("values.paidAt")}
                    </data>
                  </div>

                  <div className="is-column-seven">
                    <label>{tSGetQuoteStepSumary("deliveryAt")}</label>
                    <data className="js-shipment-table-entrega">
                      {data.entrega !== ""
                        ? data.entrega
                        : tSGetQuoteStepSumary("values.paidAt")}
                    </data>
                  </div>

                  <div className="is-column-seven">
                    <label>{tSGetQuoteStepSumary("origin")}</label>
                    <data className="js-shipment-table-origen">
                      {data.origen &&
                      data.origen !== tSGetQuoteStepTwo("choose")
                        ? data.origen.substring(0, 3)
                        : tSGetQuoteStepSumary("values.origin")}
                    </data>
                  </div>

                  <div className="is-column-seven">
                    <label>{tSGetQuoteStepSumary("destination")}</label>
                    <data className="js-shipment-table-destino">
                      {data.destino &&
                      data.destino !== tSGetQuoteStepTwo("choose")
                        ? data.destino.substring(0, 3)
                        : tSGetQuoteStepSumary("values.destination")}
                    </data>
                  </div>

                  <div className="is-column-seven">
                    <label>{tSGetQuoteStepSumary("piece")}</label>
                    <data className="js-shipment-table-pieza">
                      {data.piezas > 0
                        ? `${data.piezas} ${tSGetQuoteStepSumary(
                            "values.piece"
                          )}`
                        : tSGetQuoteStepSumary("values.piece")}
                    </data>
                  </div>

                  <div className="is-column-seven">
                    <label>{tSGetQuoteStepSumary("weight")}</label>
                    <data className="js-shipment-table-peso">
                      {data.peso !== "" ? `${data.peso} Kg.` : "N/A"}
                    </data>
                  </div>
                </div>
              </li>
            </ul>

            <h4
              className="set fadeInUp"
              style={{ visibility: "visible", animationName: "fadeInUp" }}
            >
              {tSGetQuoteStepSumary("title.text3")}
            </h4>
            <ul>
              <li>
                <div className="shipment__table__header">
                  <div className="is-column-four">
                    {tSGetQuoteStepSumary("description")}
                  </div>
                  <div className="is-column-four">
                    {tSGetQuoteStepSumary("declaredValue")}
                  </div>
                  <div className="is-column-four">
                    {tSGetQuoteStepSumary("postalCode")}
                  </div>
                  <div className="is-column-four">
                    {tSGetQuoteStepSumary("otherCharges")}
                  </div>
                </div>

                <div className="shipment__table__body">
                  <div className="is-column-four">
                    <label>{tSGetQuoteStepSumary("description")}</label>
                    <data className="js-shipment-table-description">
                      {data.description !== "" ? data.description : "N/A"}
                    </data>
                  </div>

                  <div className="is-column-four">
                    <label>{tSGetQuoteStepSumary("declaredValue")}</label>
                    <data className="js-shipment-table-valor">
                      {data.valor !== 0.0 || "" ? `${data.valor} MXN` : "N/A"}
                    </data>
                  </div>

                  <div className="is-column-four">
                    <label>{tSGetQuoteStepSumary("postalCode")}</label>
                    <data className="js-shipment-table-domicilio">
                      {data.domicilio_cp !== "" ? data.domicilio_cp : "N/A"}
                    </data>
                  </div>

                  <div className="is-column-four">
                    <label>{tSGetQuoteStepSumary("otherCharges")}</label>
                    <data className="js-shipment-table-otros-cargos">N/A</data>
                  </div>
                </div>
              </li>
            </ul>

            <div
              className={`shipment__form__column__message__services ${
                data.tipo_servicio === "Animales Vivos" &&
                (data.origen === "PBC" ||
                  data.origen === "MEX2" ||
                  data.destino === "PBC" ||
                  data.destino === "MEX2")
                  ? "show_alert"
                  : ""
              }`}
              role="alert"
            >
              {tSGetQuoteStepSumary("alertAnimals")}
            </div>
          </div>

          <div className="shipment__table__information__footer">
            <div
              className="shipment__table__information__footer__row"
              role="row"
            >
              <div className="shipment__table__information__footer__column__left">
                <input
                  type="checkbox"
                  name="accept"
                  id="accept"
                  className="checkbox"
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="accept" className="checkbox__label is-square">
                  <span>
                    <svg viewBox="0 0 13 10" xmlns="http://www.w3.org/2000/svg">
                      {" "}
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.72458 9.99948L-4.80413e-05 5.40592L2.01173 3.4499L4.72458 6.08779L10.9871 -0.000144958L12.9999 1.95554L4.72458 9.99948Z"
                      ></path>{" "}
                    </svg>
                  </span>
                  <data>{tSGetQuoteStepSumary("info.text1")} </data>&nbsp;
                </label>
                <a
                  href={`/${tNabvar("category-services.privacy")}`}
                  id="avisoPrivacidad"
                  target="blank"
                >
                  {tSGetQuoteStepSumary("info.text2")}
                </a>
              </div>

              <div className="shipment__table__information__footer__column__right">
                <button
                  value="create"
                  name="create"
                  id="quoteButton"
                  type="submit" // Cambiado a "button" en lugar de "submit"
                  className={`button button-primary js-shipment-quote-form-submit ${
                    isCheckboxChecked ? "" : "is-disabled"
                  }`}
                  disabled={
                    !isCheckboxChecked ||
                    (data.tipo_servicio === "Animales Vivos" &&
                      (data.origen === "PBC" ||
                        data.origen === "MEX2" ||
                        data.destino === "PBC" ||
                        data.destino === "MEX2"))
                  }
                >
                  {tSGetQuoteStepSumary("button")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Summary;
