import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./style.css";

function StepFour({ onhandleStepFour, dataOne, dataTwo }) {
  const [tSStatiosNac] = useTranslation("stationsNationals");
  const [tSGetQuoteStepFour] = useTranslation("getQuoteStepFour");
  const [tSGetQuoteStepSumary] = useTranslation("getQuoteStepSumary");
  const infoStations = tSStatiosNac("nationals", { returnObjects: true });
  const infoStation =
    infoStations.find((s) => s.abbreviation === dataTwo.destino) || [];

  const stations = JSON.parse(localStorage.getItem("stations"));
  const station = stations.find((s) => s.code_text === dataTwo.destino) || [];

  const [originSelect, setOriginSelect] = useState("oficinas");
  const [cpValid, setCpValid] = useState(null);
  const [showAlert, setShowAlert] = useState(false);

  const [formData, setFormData] = useState({
    entrega: originSelect,
    oficina_direccion: station.address,
    domicilio_cp: "",
  });

  const capturarCp = (e) => {
    const postalCodes = JSON.parse(localStorage.getItem("postalCodes"));
    const cp = document.querySelector(
      ".js-shipment-quote-form-domicilio-text"
    ).value;
    const destinoCp = postalCodes.find((c) => c.station === dataTwo.destino);
    if (originSelect === "domicilio" && destinoCp.codes.includes(cp)) {
      setCpValid(true);
      setShowAlert(true);
    } else {
      setCpValid(false);
      setShowAlert(true);
    }
  };

  const handleStepFour = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    setOriginSelect(formData.entrega);
    onhandleStepFour(formData);
  }, [formData, formData.entrega, onhandleStepFour]);

  useEffect(() => {
    if (formData.oficina_direccion !== station.address) {
      setFormData((prevState) => ({
        ...prevState,
        oficina_direccion: station.address,
      }));
    }
  }, [station.address, formData.oficina_direccion]);

  return (
    <>
      <div
        className="shipment__step set fadeInUp"
        style={{ visibility: "visible", animationName: "fadeInUp" }}
      >
        <h4>
          <span>{tSGetQuoteStepFour("step1")}</span>
          {tSGetQuoteStepFour("step2")}
        </h4>
      </div>

      <div className="shipment__form__delivery">
        <ul>
          <li>
            <input
              type="radio"
              name="entrega"
              id="oficinas"
              defaultChecked
              value={tSGetQuoteStepSumary("values.paymentAtOrigin")}
              className="checkbox js-shipment-quote-form-oficinas"
              onChange={handleStepFour}
            />
            <label htmlFor="oficinas" className="checkbox__label">
              <span></span>
              <data>{tSGetQuoteStepFour("inOffice")}</data>
            </label>
            <input
              type="text"
              name="oficina_direccion"
              className="js-shipment-quote-form-oficinas-text is-disabled"
              disabled
              value={infoStation.address}
              onChange={handleStepFour}
            />
          </li>

          <li
            className={`js-shipment-quote-form-option-domicilio ${
              dataOne.tipo_servicio !== "Pharma XPS" &&
              dataOne.tipo_servicio !== "Pharma STD"
                ? ""
                : "noActive"
            } ${station.add_postal_code === 0 ? "noActive" : ""}`}
          >
            <input
              type="radio"
              name="entrega"
              id="domicilio"
              value={tSGetQuoteStepSumary("values.paymentAtArrival")}
              className="checkbox js-shipment-quote-form-domicilio"
              onChange={handleStepFour}
            />
            <label
              htmlFor="domicilio"
              id="label_domicilio"
              className="checkbox__label"
            >
              <span></span>
              <data>{tSGetQuoteStepFour("atHome")}</data>
            </label>
            <div
              className="shipment__form__delivery__message__payment is-alert-important"
              role="alert"
              style={{ visibility: "inherit", opacity: "1" }}
            >
              <strong>{tSGetQuoteStepFour("info.text1")}</strong>
              {tSGetQuoteStepFour("info.text2")}
            </div>
            <div className="shipment__form__delivery__row" role="row">
              <div className="shipment__form__delivery__text">
                <span>{tSGetQuoteStepFour("postalCode")}</span>
              </div>
              <div className="shipment__form__delivery__input">
                <input
                  type="number"
                  name="domicilio_cp"
                  pattern="[0-9]*"
                  maxLength={5}
                  className={`js-shipment-quote-form-domicilio-text ${
                    cpValid === false ? "is-error" : ""
                  } ${cpValid === true ? "is-success" : ""}`}
                  onChange={(e) => {
                    handleStepFour(e);
                    capturarCp(e);
                  }}
                  disabled={formData.entrega !== "domicilio"}
                />
                <div
                  className={`shipment__form__delivery__message ${
                    cpValid === false
                      ? "shipment__form__delivery__message_error"
                      : "shipment__form__delivery__message_success"
                  }`}
                  role="alert"
                  style={
                    showAlert
                      ? {
                          visibility: "visible",
                          opacity: "1",
                          display: "block",
                          color: cpValid === false ? "#E91B2F" : "#004A90",
                        }
                      : {}
                  }
                >
                  {cpValid === false
                    ? tSGetQuoteStepFour("alertError")
                    : tSGetQuoteStepFour("alertSuccess")}
                </div>
                <div className="shipment__form__delivery__input__loader">
                  <svg className="svg-circular" viewBox="25 25 50 50">
                    <circle
                      className="path"
                      cx="50"
                      cy="50"
                      r="20"
                      fill="none"
                      strokeWidth="4"
                      strokeMiterlimit="10"
                    ></circle>
                  </svg>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
}

export default StepFour;
